import React from 'react';
import './App.css';
import TimerComponent from "./TimerComponent";
import {Row, Col, Container} from "react-bootstrap";

function App() {
  return (
      <Container fluid="true" className="App-header">
        <Row style={{alignItems: 'center',
               justifyContent: 'center',}}>
          <Col md={{span:12}}>
            <TimerComponent/>
          </Col>
        </Row>
      </Container>
  );
}

export default App;
