import React from "react";
import {Popover, OverlayTrigger} from "react-bootstrap";

export default class TimerComponent extends React.Component {
    constructor() {
      super();
      this.state = { time: {}, days: 0, startDate: new Date(2018, 11, 29, 0, 0, 0, 0), fireOnce: false, hideDelay: 1200};
    }
    
    currentElapsedTime = () => {
        var diff = new Date().getTime() - this.state.startDate.getTime();
        var days = diff / (1000 * 60 * 60 * 24)

        return days;
    }

    popover = (props) => {
        return(
        <Popover id="po-explanation" {...props}>
            <Popover.Content onMouseOver={() => this.setPopoverDelay()} onMouseOut={() => this.setPopoverDelayZero()}>
                <b>Privind Aprobarea Obiectivului de Investiții Autostrada Iași-Târgu Mureș, Autostrada Unirii</b>
                 <br/>
                 Sursă: <a href="http://www.monitoruljuridic.ro/act/lege-nr-291-din-29-noiembrie-2018-privind-aprobarea-obiectivului-de-investi-ii-autostrada-ia-i-t-rgu-mure-autostrada-unirii-emitent-208177.html">Monitorul Oficial</a>
            </Popover.Content>
        </Popover>)
    }
  
    componentDidMount() {
      if (!this.state.fireOnce)
      {
        this.setState({
            days: this.currentElapsedTime()
        });
      }
      setInterval(() => {
            this.setState({
                days: this.currentElapsedTime(),
                fireOnce: true
            })
      }, 3600);
    }

    setPopoverDelay = () => {
        this.setState({
            hideDelay: 1200
        });
    }

    setPopoverDelayZero = () => {
        this.setState({
            hideDelay: 0
        });
    }

    render() {
      return(
        <div>
          <OverlayTrigger trigger="hover" placement="top" delay={{hide: this.state.hideDelay}} shouldUpdatePosition={true} overlay={this.popover}>
            <p style={{"font-size": "3vw", "text-align":"center", "width":"auto"}}>Zile de când n-au început lucrările la A8:</p>
          </OverlayTrigger>
            <p style={{"font-size":"6vw", "text-align":"center"}}>{this.state.days << 0}</p>
        </div>
      );
    }
  }